import {
  DataListsEndpoints,
  ICryptoCurrency,
  IGeographies,
  ISettingsResponse,
} from '@/models/dataLists.model';
import { ApiResponse, httpGet, httpPost } from './httpClient';
import {
  IVerifyCryptoWalletDTO,
  IVerifyCryptoWalletResponse,
} from '@/modules/DataLists/feature/models';

class DataListsAPI {
  static fetchSettings(params: void): ApiResponse<ISettingsResponse> {
    return httpGet<ISettingsResponse>(DataListsEndpoints.SETTINGS, {
      params,
    });
  }

  static fetchGeographies(): ApiResponse<IGeographies[]> {
    return httpGet<IGeographies[]>(
      `${DataListsEndpoints.SETTINGS}/geographies`,
    );
  }

  static fetchCryptoCurrencies(): ApiResponse<ICryptoCurrency[]> {
    return httpGet<ICryptoCurrency[]>(
      `${DataListsEndpoints.PROFILES}/crypto-currencies`,
    );
  }
  static verifyCryptoWalletAddress(
    data: IVerifyCryptoWalletDTO,
  ): ApiResponse<IVerifyCryptoWalletResponse> {
    return httpPost(
      `${DataListsEndpoints.PROFILES}/${data.userId}/crypto-wallet-validation`,
      {
        address: data.address,
        currency: data.currency,
        network: data.network,
      },
    );
  }
}

export default DataListsAPI;
