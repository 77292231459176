import { TFunction } from 'react-i18next';
import { IMainCampaignGoal } from './types';

export const getCampaignGoalOptions = (t: TFunction) => [
  {
    value: IMainCampaignGoal.FOLLOWER,
    label: t(IMainCampaignGoal.FOLLOWER, { ns: 'campaigns' }),
  },
  {
    value: IMainCampaignGoal.ENGAGEMENT,
    label: t(IMainCampaignGoal.ENGAGEMENT, { ns: 'campaigns' }),
  },
  {
    value: IMainCampaignGoal.USER_SIGN_UPS,
    label: t(IMainCampaignGoal.USER_SIGN_UPS, { ns: 'campaigns' }),
  },
  {
    value: IMainCampaignGoal.PERSONALIZED,
    label: t(IMainCampaignGoal.PERSONALIZED, { ns: 'campaigns' }),
  },
];
