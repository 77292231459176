import { DocumentStatuses, SigningTypes } from './documents.model';
import { IGetListParamsBase } from './api.model';
import { SocialChannels } from './socialChannel.model';
import { CryptoCurrenciesEnum, WalletNetworksEnum } from '@/models/web3.model';
import { ICampaignResults } from './payout.model';

export enum CampaignEndpoints {
  GET_CAMPAIGNS = '/admin/campaigns',
}

export interface IResources {
  fileName: string;
  link: string;
}

export enum CampaignStatuses {
  PENDING = 'pending',
  NOT_VERIFIED = 'not_verified',
  IN_VERIFICATION = 'in_verification',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
  ACTIVE = 'active',
  EXPIRED = 'expired',
  PAUSED = 'paused',
  PROJECT_PAUSED = 'project_paused',
  CANCELED = 'canceled',
  ARCHIVED = 'archived',
  COMPLETED = 'completed',
  DELIVERY_FINISHED = 'delivery_finished',
}

export enum CampaignParticipantStatuses {
  PENDING = 'pending',
  IN_VERIFICATION = 'in_verification',
  IN_REVIEW = 'in_review',
  REJECTED = 'rejected',
  WAITING_FOR_PROJECT_SIGN = 'waiting_for_project_sign',
  ACCEPTED = 'accepted',
  EXPIRED = 'expired',
}

export enum CampaignDocumentTypes {
  SAFT = 'saft',
  SUPPLEMENTARY_AGREEMENT = 'supplementary_agreement',
  SERVICE_AGREEMENT = 'service_agreement',
  MARKETING_SERVICE_AGREEMENT = 'marketing_service_agreement',
  CAMPAIGN_SERVICE_AGREEMENT = 'campaign_service_agreement',
  TERMS_AND_CONDITIONS = 'terms_and_conditions',
}

export interface ICommunity {
  id: number;
  value: string;
  disabled: boolean;
}

export interface IUsedBudget {
  total: number;
  socials: {
    [key in SocialChannels]?: number;
  };
}

export interface IMarketingChannel {
  name: SocialChannels;
  percent: number;
  description: string;
  isEditable?: boolean;
}

export type TChannelsConfig = {
  [key: string]: {
    posts?: number;
    deliveredPosts?: number;
  };
};

export interface IContentDistributionPlan {
  channelsConfig: TChannelsConfig;
  isDisabled: boolean;
  startDate: string;
  endDate: string;
  weekNumber: number;
}

export interface IDocuments {
  signedDocumentLink: string;
  id: string;
  type: CampaignDocumentTypes;
  signingType: SigningTypes;
  status: DocumentStatuses;
  clickwrapId: string;
  agreementId: string;
  envelopeId: string;
  signedOn: string;
  declinedOn: string;
  version: string;
  versionId: string;
  versionNumber: string;
}

export interface IParticipationItems {
  id: string;
  sk: string;
  acceptedAt: string;
  projectId: string;
  participantId: string;
  campaignMarketingStartDate: string;
  campaignMarketingEndDate: string;
  projectImage?: string;
  totalSocialChannelsBookedPostsCount?: number;
  totalSocialChannelsDeliveredPostsCount?: number;
  campaignId: string;
  totalInvestedMoneyAmount: number;
  projectSubTitle: string;
  campaignGoal?: string;
  payoutCurrency?: CryptoCurrenciesEnum;
  payoutNetwork?: WalletNetworksEnum;
  campaignBanner?: string;
  status: CampaignParticipantStatuses;
  alias: string;
  mainCommunity?: ICommunity;
  secondaryCommunity?: ICommunity;
  firstName: string;
  lastName: string;
  campaignTitle: string;
  projectTitle: string;
  totalParticipationAmount: number;
  socialChannels: ICampaignSocialChannel[];
  contentDistributionPlan: IContentDistributionPlan[];
  participantDocuments: IDocuments[];
  projectDocuments: IDocuments[];
}

export interface ISocialChannels {
  name: SocialChannels;
  link: string;
}

export interface ICampaign {
  id: string;
  title: string;
  projectTitle: string;
  marketingStartDate: string;
  marketingEndDate: string;
  marketingBudget: number;
  status: CampaignStatuses;
  marketingStrategy: string;
  currency?: CryptoCurrenciesEnum;
  network?: WalletNetworksEnum;
  payoutCurrency?: CryptoCurrenciesEnum;
  payoutNetwork?: WalletNetworksEnum;
  socialChannels: IMarketingChannel[];
  resources: IResources[];
  usedBudget?: IUsedBudget;
  totalKolParticipations?: IUsedBudget;
  sortId: string;
  projectId: string;
  projectDescription: string;
  projectFullDescription: string;
  projectImage: string;
  projectMainGeographyTitle: string;
  projectSecondaryGeographyTitle: string;
  projectMainCommunityTitle: string;
  projectSecondaryCommunityTitle: string;
  seedHunterWalletNetwork?: WalletNetworksEnum;
  seedHunterWalletAddress: string;
  campaignBanner?: string;
  projectSubTitle?: string;
  hashTag?: string;
  usp?: string;
  campaignDescription?: string;
  goalDescription?: string;
  goalUrl?: string;
  campaignGoal?: string;
  commissionPercent?: number;
  goalSocialChannels?: SocialChannels[];
  unreadRequestsCount?: number;
  projectSocialChannel?: ISocialChannels[];
  projectWebsite?: string;
  projectWhitepaper?: string;
  campaignResults?: ICampaignResults;
  totalSeedHunterCommission?: number;
}

export interface ICampaignSocialChannel {
  name: SocialChannels;
  percent: number;
  description?: string;
  link?: string;
  pricePerPost?: number;
  allowedPosts?: number;
  bookedPosts?: number;
}

export interface ICampaignKols {
  id: string;
  name: string;
}

// ============= DTO =============

export interface IGetCampaignsListDTO extends IGetListParamsBase {
  search?: string;
  isInternal?: boolean;
}

export interface IGetParticipationDTO extends IGetListParamsBase {
  id?: string;
  startSk?: string;
}

export interface ICampaignDTO {
  id?: string;
  title?: string;
  marketingStartDate?: string;
  marketingEndDate?: string;
  hashTag?: string;
  usp?: string;
  campaignDescription?: string;
  goalDescription?: string;
  goalUrl?: string;
  campaignGoal?: string;
  goalSocialChannels?: SocialChannels[];
  socialChannels?: IMarketingChannel[];
  currency?: CryptoCurrenciesEnum;
  network?: WalletNetworksEnum;
  resources?: IResources[];
}

export interface IUpdateCampaignsStatusDTO extends ICampaignDTO {
  id?: string;
  reason?: string;
  status: CampaignStatuses;
}

// ============== Response ==============

export interface ICampaignsListResponse {
  items: ICampaign[];
  hasMore: boolean;
}

export interface IParticipationResponse {
  items: IParticipationItems[];
  hasMore: boolean;
}

export interface ICampaignDetailsResponse {
  data: ICampaign;
}
