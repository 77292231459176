export { ReactComponent as DashboardIcon } from './DashboardIcon.svg';
export { ReactComponent as AffiliateIcon } from './AffiliateIcon.svg';
export { ReactComponent as ListIcon } from './ListIcon.svg';
export { ReactComponent as LogoutIcon } from './LogoutIcon.svg';
export { ReactComponent as MyAccountIcon } from './MyAccountIcon.svg';
export { ReactComponent as TabOptionsIcon } from './TabOptionsIcon.svg';
export { ReactComponent as MessageGhostIcon } from './MessageGhostIcon.svg';
export { ReactComponent as MessageIcon } from './MessageIcon.svg';
export { ReactComponent as TelephoneGhostIcon } from './TelephoneGhostIcon.svg';
export { ReactComponent as InternetGhostIcon } from './InternetGhostIcon.svg';
export { ReactComponent as ChatGhostIcon } from './ChatGhostIcon.svg';
export { ReactComponent as ArrowRightIcon } from './ArrowRightIcon.svg';
export { ReactComponent as EditIcon } from './EditIcon.svg';
export { ReactComponent as FileIcon } from './FileIcon.svg';
export { ReactComponent as TextFileIcon } from './TextFileIcon.svg';
export { ReactComponent as DiagramIcon } from './DiagramIcon.svg';
export { ReactComponent as CloseIcon } from './CloseIcon.svg';
export { ReactComponent as SearchIcon } from './SearchIcon.svg';
export { ReactComponent as FullChevronIcon } from './FullChevronIcon.svg';
export { ReactComponent as CrossInCircleIcon } from './CrossInCircleIcon.svg';
export { ReactComponent as DeleteCircleIcon } from './DeleteCircleIcon.svg';
export { ReactComponent as UploadIcon } from './UploadIcon.svg';
export { ReactComponent as LockIcon } from './LockIcon.svg';
export { ReactComponent as SpeakerIcon } from './SpeakerIcon.svg';
export { ReactComponent as LockOpenedIcon } from './LockOpenedIcon.svg';
export { ReactComponent as ChevronIcon } from './ChevronIcon.svg';
export { ReactComponent as TGEBadgeIcon } from './TGEBadgeIcon.svg';
export { ReactComponent as MinusButtonIcon } from './MinusButtonIcon.svg';
export { ReactComponent as PlusButtonIcon } from './PlusButtonIcon.svg';
export { ReactComponent as ShareSecondaryIcon } from './ShareSecondaryIcon.svg';
export { ReactComponent as InfoBlueIcon } from './InfoBlueIcon.svg';
export { ReactComponent as FilterIcon } from './FilterIcon.svg';
export { ReactComponent as XButtonIcon } from './XButtonIcon.svg';
export { ReactComponent as FlagIcon } from './FlagIcon.svg';
export { ReactComponent as CrossIcon } from './CrossIcon.svg';
export { ReactComponent as FilledFileIcon } from './FilledFileIcon.svg';
export { ReactComponent as GemIcon } from './GemIcon.svg';
export { ReactComponent as ExportIcon } from './ExportIcon.svg';
export { ReactComponent as RoundArrowsIcon } from './RoundArrowsIcon.svg';
export { ReactComponent as WalletIcon } from './WalletIcon.svg';
export { ReactComponent as FilmIcon } from './FilmIcon.svg';
export { ReactComponent as PhoneIcon } from './PhoneIcon.svg';
export { ReactComponent as WhatsAppIcon } from './WhatsAppIcon.svg';
export { ReactComponent as LogoIcon } from './LogoIcon.svg';
export { ReactComponent as DownIcon } from './DownIcon.svg';
export { ReactComponent as OrangeCornerBadgeIcon } from './OrangeCornerBadgeIcon.svg';
export * from './StatusIcons';
export * from './FaceBook';
export * from './Instagram';
export * from './Youtube';
export * from './Twitter';
export * from './Tiktok';
export * from './Reddit';
export * from './Telegram';
export { LinkedinSVGIcon, LinkedinDisabledIcon } from './LinkedIn';
