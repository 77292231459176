import dayjs from 'dayjs';
import { ICampaign } from '@/models/campaigns.model';

export const normalizedCampaign = (campaign: ICampaign | null) => {
  return {
    ...campaign,
    marketingStartDate:
      campaign?.marketingStartDate && dayjs(campaign?.marketingStartDate),
    marketingEndDate:
      campaign?.marketingEndDate && dayjs(campaign?.marketingEndDate),
  } as ICampaign;
};
