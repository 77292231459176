// Globals
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledFrom,
  StyledFormItem,
  StyledInfoWrapper,
  StyledTextArea,
  StyledChannelsWrapper,
  StyledCheckbox,
  StyledButton,
  Text,
  StyledRequestWrap,
} from './CampaignInfoStyles';
import { StyledPageWrapper, StyledSelect, StyledChannels } from '../Styled';
import { Checkbox, CheckboxGroup, StyledInput, TextEditor } from '@/ui';
import { IFormInstance } from '@/components';

// Models
import { ICampaign } from '@/models/campaigns.model';

// Modules
import { LogContainer } from '@/modules/Logs';
import {
  ProfileRequestList,
  selectOwnRequestsListData,
} from '@/modules/Requests';
import { socialChannelsConfig, socialChannelsList } from '@/modules/KolsList';

// Helpers | Utils
import { ICampaignFields } from '../../types';
import { getCampaignGoalOptions } from '../../helpers';
import { useGetFormValidationRules } from '../../hooks';
import { TextLength } from '@/constants';
import { ROUTES_PATHS } from '@/router';
import { useAppState } from '@/hooks';
import { LogEntities } from '@/models/logs.model';

interface ICampaignInfo {
  campaign: ICampaign;
  handleChange?: (values: ICampaign, allValues: ICampaign) => void;
  formInstance?: IFormInstance;
  isChannelsShown?: boolean;
  disabled?: boolean;
}

const CampaignInfo = ({
  campaign,
  formInstance,
  handleChange,
  isChannelsShown,
  disabled,
}: ICampaignInfo) => {
  const { t } = useTranslation(['campaigns']);
  const navigate = useNavigate();
  const validationRules = useGetFormValidationRules();
  const requestsListData = useAppState(selectOwnRequestsListData);

  const navigateToRequests = () => {
    navigate(ROUTES_PATHS.REQUESTS);
  };

  return (
    <StyledPageWrapper>
      {requestsListData?.items?.length !== 0 && (
        <StyledRequestWrap>
          <StyledButton type="ghost" onClick={navigateToRequests}>
            <Text>{t('go_to_requests')}</Text>
          </StyledButton>
          <ProfileRequestList />
        </StyledRequestWrap>
      )}
      <StyledFrom
        onValuesChange={handleChange}
        form={formInstance}
        initialValues={campaign}
        disabled={disabled}
      >
        <StyledInfoWrapper $isColumn>
          <StyledFormItem
            name={ICampaignFields.TITLE}
            rules={validationRules[ICampaignFields.TITLE]}
            label={t('campaigns_detail_title')}
          >
            <StyledInput />
          </StyledFormItem>

          <StyledFormItem
            name={ICampaignFields.CAMPAIGN_DESCRIPTION}
            rules={validationRules[ICampaignFields.CAMPAIGN_DESCRIPTION]}
            label={t('campaign_description_label')}
          >
            <StyledTextArea rows={5} showCount maxLength={TextLength?.long} />
          </StyledFormItem>

          <StyledFormItem
            name={ICampaignFields.HASHTAG}
            rules={validationRules[ICampaignFields.HASHTAG]}
            label={t('campaign_hashtag_label')}
          >
            <StyledInput />
          </StyledFormItem>

          <StyledFormItem
            name={ICampaignFields.IS_INTERNAL}
            label={t('campaign_is_internal_label')}
            valuePropName="checked"
          >
            <Checkbox />
          </StyledFormItem>

          <StyledFormItem
            name={ICampaignFields.CAMPAIGN_GOAL}
            rules={validationRules[ICampaignFields.CAMPAIGN_GOAL]}
            label={t('campaign_main_goal_label')}
          >
            <StyledSelect options={getCampaignGoalOptions(t)} />
          </StyledFormItem>

          {isChannelsShown && (
            <StyledFormItem
              name={ICampaignFields.GOAL_CHANNELS}
              rules={validationRules[ICampaignFields.GOAL_CHANNELS]}
              label={t('campaign_campaign_channels_label')}
            >
              <CheckboxGroup>
                {socialChannelsList?.map((option) => (
                  <StyledCheckbox key={option} value={option}>
                    <StyledChannelsWrapper>
                      <StyledChannels>
                        {socialChannelsConfig[option ?? '']?.['icon']}
                      </StyledChannels>
                    </StyledChannelsWrapper>
                  </StyledCheckbox>
                ))}
              </CheckboxGroup>
            </StyledFormItem>
          )}

          {campaign?.campaignGoal && !isChannelsShown && (
            <StyledFormItem
              name={ICampaignFields.GOAL_URL}
              rules={validationRules[ICampaignFields.GOAL_URL]}
              label={t('campaign_url_label')}
            >
              <StyledInput />
            </StyledFormItem>
          )}

          <StyledFormItem
            name={ICampaignFields.GOAL_DESCRIPTION}
            rules={validationRules[ICampaignFields.GOAL_DESCRIPTION]}
            label={t('campaign_goal_description_label')}
          >
            <StyledTextArea rows={5} showCount maxLength={TextLength.medium} />
          </StyledFormItem>

          <StyledFormItem
            name={ICampaignFields.USP}
            rules={validationRules[ICampaignFields.USP]}
            label={t('campaign_product_label')}
          >
            <TextEditor readOnly={disabled} />
          </StyledFormItem>
        </StyledInfoWrapper>
      </StyledFrom>
      <LogContainer entity={LogEntities.CAMPAIGN} id={campaign?.id} />
    </StyledPageWrapper>
  );
};

export default CampaignInfo;
