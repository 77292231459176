import { IMarketingChannel, IResources } from '@/models/campaigns.model';
import { SocialChannels } from '@/models/socialChannel.model';

export enum ICampaignFields {
  TITLE = 'title',
  BUDGET = 'marketingBudget',
  RESOURCES = 'resources',
  MARKETING_START_DATE = 'marketingStartDate',
  MARKETING_END_DATE = 'marketingEndDate',
  MARKETING_STRATEGY = 'marketingStrategy',
  CAMPAIGN_DESCRIPTION = 'campaignDescription',
  HASHTAG = 'hashTag',
  CAMPAIGN_GOAL = 'campaignGoal',
  CAMPAIGN_BANNER = 'campaignBanner',
  GOAL_URL = 'goalUrl',
  GOAL_DESCRIPTION = 'goalDescription',
  GOAL_CHANNELS = 'goalSocialChannels',
  IS_INTERNAL = 'isInternal',
  USP = 'usp',
}

export enum ICampaignsTabsNames {
  CAMPAIGN = 'Campaign',
  RESOURCES = 'Resources',
  CONTENT_DISTRIBUTION = 'Content Distribution',
}

export enum ContentDistributionDataTypes {
  WEEKLY_DELIVERY = 'WEEKLY_DELIVERY',
  TOTAL_DELIVERY = 'TOTAL_DELIVERY',
  IMPRESSIONS = 'IMPRESSIONS',
}

export type TContentDataType = {
  title: string;
  units: string;
  totalUnits: string;
};

export enum IMainCampaignGoal {
  FOLLOWER = 'follower_growth_increase_number_of_followers',
  ENGAGEMENT = 'engagement_growth_increase_community_engagement',
  USER_SIGN_UPS = 'increase_user_sign_ups_internal_user_base',
  PERSONALIZED = 'personalized_goal',
}

export type TContentDataConfig = {
  [key: string]: TContentDataType;
};

export type ICampaign = {
  [ICampaignFields.TITLE]?: string;
  [ICampaignFields.MARKETING_START_DATE]?: string;
  [ICampaignFields.MARKETING_END_DATE]?: string;
  [ICampaignFields.HASHTAG]?: string;
  [ICampaignFields.USP]?: string;
  [ICampaignFields.CAMPAIGN_DESCRIPTION]?: string;
  [ICampaignFields.GOAL_DESCRIPTION]?: string;
  [ICampaignFields.GOAL_URL]?: string;
  [ICampaignFields.CAMPAIGN_GOAL]?: string;
  [ICampaignFields.CAMPAIGN_BANNER]?: string;
  [ICampaignFields.IS_INTERNAL]?: boolean;
  [ICampaignFields.GOAL_CHANNELS]?: SocialChannels[];
  [ICampaignFields.RESOURCES]?: IResources[] | null;
  socialChannels?: IMarketingChannel[];
};

export const socialChannelsConfig: TContentDataConfig = {
  [ContentDistributionDataTypes.WEEKLY_DELIVERY]: {
    title: 'post',
    units: '',
    totalUnits: '',
  },
  [ContentDistributionDataTypes.TOTAL_DELIVERY]: {
    title: 'tweet',
    units: '',
    totalUnits: '',
  },
  [ContentDistributionDataTypes.IMPRESSIONS]: {
    title: 'video',
    units: '',
    totalUnits: '',
  },
};

export const CAMPAIGN_GOALS_WITH_SOCIALS = new Set([
  IMainCampaignGoal.ENGAGEMENT,
  IMainCampaignGoal.FOLLOWER,
]);
