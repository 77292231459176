export enum ICampaignTabsNames {
  CAMPAIGN = 'Campaign',
  RESOURCES = 'Resources',
  MARKETING_DELIVERY = 'Marketing Delivery',
  CONTENT_DISTRIBUTION = 'Content Distribution',
  PARTICIPATION = 'Participations',
  RESULTS = 'Results',
}

export enum ICampaignFields {
  TITLE = 'title',
  CAMPAIGN_DESCRIPTION = 'campaignDescription',
  HASHTAG = 'hashTag',
  CAMPAIGN_BANNER = 'campaignBanner',
  CAMPAIGN_GOAL = 'campaignGoal',
  GOAL_URL = 'goalUrl',
  GOAL_DESCRIPTION = 'goalDescription',
  GOAL_CHANNELS = 'goalSocialChannels',
  USP = 'usp',
  BUDGET = 'marketingBudget',
  RESOURCES = 'resources',
  MARKETING_START_DATE = 'marketingStartDate',
  MARKETING_END_DATE = 'marketingEndDate',
  MARKETING_STRATEGY = 'marketingStrategy',
  IS_INTERNAL = 'isInternal',
}
